import logo from './Logo.png';
import './App.css';
import './static/Login.css'

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Card, Divider, Input,Button, Space, Upload, Col, Row, Image, Layout, Menu,  notification, Flex, Typography    } from 'antd';
import { UploadOutlined, HomeOutlined, UserOutlined, MailOutlined, PhoneOutlined, LogoutOutlined  } from '@ant-design/icons';

import { List } from 'antd';
import Axios from './Axios.js';






// 側邊攔
const { Content, Sider } = Layout;

function getItem(label, key, icon) {
  return {
    key,
    icon,
    label,
  };
}
const items = [ 
  getItem('Home', '/', <HomeOutlined />),
  getItem('Profile', '/profile', <UserOutlined />),
  getItem('Images', '/showallimages', <HomeOutlined />),
  getItem('Logout', '/Login', <LogoutOutlined />),
];





// Login
export function Login() {
  let navigate = useNavigate();
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');

  function handleLogin() {

    if (!account || !password) {
      notification.error({
        message: '登入錯誤',
        description: '帳號密碼不能為空!',
        duration: 2,
      });
      return; // 提前返回以避免進一步的處理
    }

    const apiUrl = '/login/';

    Axios().post(apiUrl, {
      account: account,
      password: password,
    })
    .then(response => {
      window.localStorage.setItem('jwt', response.data.token);
      
      // Check if the user is an admin
      Axios().get('/picture/manage/all', {
        headers: {
          'Authorization': `Bearer ${response.data.token}`
        }
      }).then(() => {
        navigate('/admin-images'); // Admin, navigate to admin page
        console.log('Admin verified');
      }).catch(() => {
        navigate('/'); // Not an admin, navigate to homepage
        console.log('Not an admin');
      });

    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        notification.error({
          message: '登入錯誤',
          description: '帳號或密碼不存在!',
          duration: 2,
        });
      } else {
        console.error('Login Error:', error);
      }
    });
  }

  return (
    <div className='App'>
      <img src={logo} alt="logo" />
      <Card className="Card" title="Login" bordered={false}>
        <Input
          className='input'
          placeholder="Phone"
          onChange={(e) => setAccount(e.target.value)}
        />
        <Input.Password
          className='input2'
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link className='p' style={{color:'blue',margin:'5px'}} to="/register">register</Link>
        <Link className='p' style={{color:'red', marginLeft:'180px'}} to="/forgetpassword">ForgetPassword?</Link>
        <Divider />
        <button style={{marginTop:'25px'}} className="button-28" type="primary" onClick={handleLogin}>Continue</button>
      </Card>
    </div>
  );
}




  
// Register
export function Register() {
  let navigate = useNavigate();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  function handleRegister() {

    
  if (password !== confirmPassword) {
    alert('Passwords do not match!'); // 檢查密碼是否匹配
    return;
  }
    const apiUrl = '/register/';

    Axios().post(apiUrl, {
      name: name,
      phone: phone,
      account: phone, // 自動填入 phone 的值作為 account
      email: email,
      password: password
    })
    .then(response => {
      console.log('Registration Successful:', response.data);
      navigate('/login');
    })
    .catch(error => {
      console.error('Registration Error:', error);
    });
  }


  return (
    <div className='App'>
      <img src={logo} alt="logo" />

      <Card style={{height:'680px'}} className="Card-register" title="Register" bordered={false}>
      <Input
        className='input'
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <Input
        className='input'
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Input
        className='input'
        placeholder="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />

      {/* // 自動填入 phone 的值作為 account */}
      <Input
        className='input'
        placeholder="Account"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />

      <Divider>AND</Divider>

      <Input.Password
        className='input2'
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <Input.Password
          className='input'
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />


      <button className='button-28' onClick={handleRegister}>Register</button>

      </Card>
   
    </div>
  );
}



// ForgetPassword
export function ForgetPassword() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');


  function handleSendToken() {
    const apiUrl = '/private/forgetPasswd';

    Axios().post(apiUrl, {
      phone: phone,
    })
    .then(response => {
      console.log('Token sent:', response.data);
      navigate('/reset-password'); // Assuming you have this route setup
    })
    .catch(error => {
      console.error('Error sending token:', error);
    });
  }

  return (
    <div className='App'>
      <Card style={{height:'250px'}} className="Card-forgetpass" title="Forget Password" bordered={false}>
        <Input
          className='input'
          placeholder="Phone or Account"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <button style={{marginTop:'20px'}} className="button-28" onClick={handleSendToken}>Send Token</button>
      </Card>
    </div>
  );
}



// ResetPassword
const { Title } = Typography;

export function ResetPassword() {
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  function handleResetPassword() {
    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    const apiUrl = '/private/reset-password';
    Axios().post(apiUrl, {
      token: token,
      password: password
    })
    .then(response => {
      console.log('Password reset successful:', response.data);
      navigate('/login'); // Redirect to the login page or any other appropriate page
    })
    .catch(error => {
      console.error('Reset password error:', error);
    });
  }



  return (
    <div className='App'>
      <img src={logo} alt="logo" />

      <Card style={{height:'480px'}} className="Card-forgetpass" title="Reset Password" bordered={false}>

      <Flex align="flex-start" vertical>
        <Title level={5}>variant</Title>
        <Input.OTP
          variant="filled"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
      </Flex>

      <Divider>AND</Divider>

        <Input.Password
          className='input2'
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input.Password
          className='input2'
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button style={{marginTop:'40px'}} className="button-28" onClick={handleResetPassword}>Reset Password</button>
      </Card>
    </div>
  );
}




// Homepage
export function Homepage() {
  const [collapsed, setCollapsed] = useState(false);
  const [analysisResult, setAnalysisResult] = useState('');
  const [uploadedImagePath, setUploadedImagePath] = useState('');
  const [resultImagePath, setResultImagePath] = useState('');
  const navigate = useNavigate();

  const onMenuClick = (e) => {
    navigate(e.key);
  };

  const handleUpload = async (options) => {
    const { file, onSuccess, onError } = options;
    const formData = new FormData();
    const fileData = file.originFileObj || file;  // 獲取原始檔案對象
    formData.append('photo', fileData);

    // 使用 FileReader 顯示圖片
    const reader = new FileReader();
    reader.onload = (e) => {
      setUploadedImagePath(e.target.result);  // 直接使用讀取的結果作為圖片源
    };
    reader.readAsDataURL(fileData);  // 讀取檔案並將其轉換為 Data URL

    try {
      const response = await Axios().post('/picture/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.detection) {
        setAnalysisResult('違規：未戴頭盔，相關圖片已儲存');
        notification.success({
          message: '檢測成功',
          description: '違規：未戴頭盔，相關圖片已儲存'
        });
        setResultImagePath(`http://nkust.chyz.in/storage/models_results/${response.data.filename}`);
      } else {
        setAnalysisResult('無違規行為');
        notification.success({
          message: '檢測成功',
          description: '無違規行為'
        });
        setResultImagePath('');
      }
      onSuccess();
    } catch (error) {
      onError(error);
      notification.error({
        message: '檢測失敗',
        description: '上傳或檢測過程中出現問題'
      });
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <Image src={logo} alt="Logo" style={{ margin: '8px' }}/>
        <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" items={items} onClick={onMenuClick} />
      </Sider>
      <Layout>
        <Content style={{ margin: '0 16px', marginTop: 24 }}>
          <div>
            <Row gutter={16}>
              <Col span={12}>
                <Card style={{minHeight:'865px'}} title="上傳違規照片" bordered={false}>
                  <Space direction="vertical" style={{ width: '100%' }} size="large">
                    <Upload
                      customRequest={handleUpload}
                      listType="picture"
                      maxCount={1}
                      showUploadList={false}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                    {uploadedImagePath && <img src={uploadedImagePath} alt="" style={{ maxWidth: '100%', marginTop: '20px' }} />}
                  </Space>
                </Card>
              </Col>
              <Col span={12}>
                <Card style={{minHeight:'865px'}} title="分析結果" bordered={false}>
                  <p>{analysisResult}</p>
                  {resultImagePath && <img src={resultImagePath} alt="" style={{ maxWidth: '100%', marginTop: '20px' }} />}
                </Card>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}







// update User Profile
export function Profile() {
  const [collapsed, setCollapsed] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();

  const onMenuClick = (e) => {
    navigate(e.key);
  };

  // 載入時獲取用戶資料
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await Axios().get('/user/info');
        const data = response.data;
        setUsername(data.name);
        setPhone(data.phone);
        setEmail(data.email);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };
    fetchUserInfo();
  }, []);

  const handleUpdate = () => {
    const apiUrl = '/updateUser';

    Axios().post(apiUrl, {
      name: username,
      phone: phone,
      email: email,
    })
    .then(response => {
      console.log('更新成功:', response.data);
      notification.success({
        message: '更新成功',
        description: '用戶資料已更新',
      });
    })
    .catch(error => {
      console.error('更新錯誤:', error);
      notification.error({
        message: '更新異常',
        description: '網絡或伺服器錯誤',
      });
    });
  };

  return (
    <div className='App'>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
        <Image src={logo} alt="logo" style={{ margin: '8px' }}/>
        <Menu theme="dark" defaultSelectedKeys={['/profile']} mode="inline" items={items} onClick={onMenuClick} />
        </Sider>
        <Layout>
          <Card className="Card-profile" title="Profile" bordered={false}>
            <Input className='input' placeholder="Username" prefix={<UserOutlined />} value={username} onChange={e => setUsername(e.target.value)} />
            <Input className='input' placeholder="Gmail" prefix={<MailOutlined />} value={email} onChange={e => setEmail(e.target.value)} />
            <Input className='input' placeholder="Phone" prefix={<PhoneOutlined />} value={phone} onChange={e => setPhone(e.target.value)} />
            <Divider />
            <button className="button-28" onClick={handleUpdate}>Update</button>
          </Card>
        </Layout>
      </Layout>
    </div>
  );
};




// Show All Images
export function ShowAllImages() {
  const [collapsed, setCollapsed] = useState(false);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchImages();
  }, []);

  const onMenuClick = (e) => {
    navigate(e.key);
  };


  const fetchImages = async () => {
    try {
      const response = await Axios().get('/picture/user_all');
      const formattedImages = response.data.map(item => {
        const originalImgPath = `https://nkust.chyz.in/storage/user_pic/${item.file_name}`;
        let resultImgPath = ''; // 預設為空字符串，表示沒有結果圖片
        if (item.results.length > 0 && item.results[0].result_file_name) {
          resultImgPath = `https://nkust.chyz.in/storage/models_results/${item.results[0].result_file_name}`;
        }
        return {
          key: item.id,
          title: `Uploaded on: ${item.created_at.split('T')[0]} ${item.created_at.split('T')[1].split('.')[0]}`,
          originalImgPath,
          resultImgPath
        };
      });
      setImages(formattedImages);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <Image src={logo} alt="Logo" style={{ margin: '8px' }}/>
        <Menu theme="dark" defaultSelectedKeys={['/showallimages']} mode="inline" items={items} onClick={onMenuClick} />
      </Sider>
      <Content style={{ margin: '24px 16px 0' }}>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              pageSize: 3,
            }}
            dataSource={images}
            renderItem={item => (
              <List.Item key={item.key}>
                <Card title={item.title}>
                  <div style={{ marginBottom: '20px' }}>
                    <h3>Original Image</h3>
                    <Image width={200} src={item.originalImgPath} />
                  </div>
                  <div>
                    <h3>Result Image</h3>
                    <Image width={200} src={item.resultImgPath} />
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </div>
      </Content>
    </Layout>
  );
}



// AdminImages Component
export function AdminImages() {
  const [collapsed, setCollapsed] = useState(false);
  const [images, setImages] = useState([]);

  const navigate = useNavigate();
  const onMenuClick = (e) => {
    navigate(e.key);
  };

  useEffect(() => {
    fetchAdminImages();
  }, []);

  const fetchAdminImages = async () => {
    try {
      const response = await Axios().get('/picture/manage/all');
      const formattedImages = response.data.map(item => {
        const originalImgPath = `https://nkust.chyz.in/storage/user_pic/${item.file_name}`;
        let resultImgPath = ''; // 預設為空字符串，表示沒有結果圖片
        if (item.results.length > 0 && item.results[0].result_file_name) {
          resultImgPath = `https://nkust.chyz.in/storage/models_results/${item.results[0].result_file_name}`;
        }
        return {
          key: item.id,
          title: `Uploaded by User ID ${item.user_id} on: ${item.created_at.split('T')[0]} ${item.created_at.split('T')[1].split('.')[0]}`,
          originalImgPath,
          resultImgPath
        };
      });
      setImages(formattedImages);
    } catch (error) {
      console.error('Error fetching admin images:', error);
      notification.error({
        message: 'Error fetching images',
        description: 'Unable to fetch images due to server error.'
      });
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <Image src={logo} alt="logo" style={{ margin: '8px' }}/>
        <Menu theme="dark" defaultSelectedKeys={['/admin-images']} mode="inline" items={[
          getItem('Admin Images', '/admin-images', <HomeOutlined />),  
          getItem('Logout', '/Login', <LogoutOutlined />),]} 
          onClick={onMenuClick} />
      </Sider>
      <Content style={{ margin: '24px 16px 0' }}>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              pageSize: 3,
            }}
            dataSource={images}
            renderItem={item => (
              <List.Item key={item.key}>
                <Card title={item.title}>
                  <div style={{ marginBottom: '20px' }}>
                    <h3>Original Image</h3>
                    <Image width={200} src={item.originalImgPath} />
                  </div>
                  <div>
                    <h3>Result Image</h3>
                    <Image width={200} src={item.resultImgPath} />
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </div>
      </Content>
    </Layout>
  );
}

