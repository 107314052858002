import React from 'react';
import './App.css';
import { Routes, Route  } from 'react-router-dom';
import  { Login, Homepage,Register, ForgetPassword, Profile, ShowAllImages, ResetPassword, AdminImages }  from './pages'; 



function App() {

  return (

    <>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/showallimages" element={<ShowAllImages />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/admin-images" element={<AdminImages />} />
        </Routes>
    </>
    
    
  );
}

export default App;
