import axios from "axios"




function Axios(){
  // const navigate = useNavigate()
  if (window.localStorage.getItem('jwt') === null){
  window.localStorage.setItem('jwt','None')
  }
  let jwt = `Bearer ${(window.localStorage.getItem('jwt'))}`
  const res = axios.create(
    {
      baseURL: 'https://nkust.chyz.in/',
      timeout:10000,
      headers:{
        'Authorization':jwt,
        'Content-Type':'Application/json',
      }
    }
  )
  return res;
}


export default Axios